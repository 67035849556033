import type { Ref } from 'vue';
import { ref } from 'vue';
import type { Schemas } from '#shopware';
import type { Breadcrumb } from '@shopware-pwa/composables-next';

export type UseCmsStoreReturn = {
    seoUrlForeignKey: Ref<string | null>;
    updateSeoUrlForeignKey: (value: string | null) => void;
    updateProduct: (value: Schemas['Product']) => void;
    clearCmsStore: () => void;
    setLastCategory(value: LastCategory): void;
    clearLastCategoryStore(): void;
    lastCategory: Ref<LastCategory | null>;
    product: Ref<Schemas['Product'] | null>;
};

export type LastCategory = {
    id: string;
    breadcrumb: Breadcrumb[];
    seoPath: string | undefined;
    path: string;
};

/**
 * Composable to store cms data like the last category, or the product
 * @public
 * @category Productlistings
 */
export function useCmsStore(): UseCmsStoreReturn {
    const product: Ref<Schemas['Product'] | null> = useState('active-product', () => ref(null));
    const lastCategory: Ref<LastCategory | null> = useState('last-category', () => ref(null));
    const seoUrlForeignKey: Ref<string | null> = useState('seo-url-foreign-key', () => ref(null));

    const updateSeoUrlForeignKey = (value: string | null) => {
        seoUrlForeignKey.value = value;
    };
    const updateProduct = (value: Schemas['Product']) => {
        product.value = value;
    };

    const clearCmsStore = () => {
        seoUrlForeignKey.value = null;
        product.value = null;
    };

    const setLastCategory = (value: LastCategory) => {
        lastCategory.value = value;
    };

    const clearLastCategoryStore = () => {
        lastCategory.value = null;
    };

    return {
        updateSeoUrlForeignKey,
        seoUrlForeignKey,
        clearCmsStore,
        product,
        updateProduct,
        setLastCategory,
        clearLastCategoryStore,
        lastCategory,
    };
}
